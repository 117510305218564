import ApplicationController from './application'

export default class extends ApplicationController {
  connect() {
    setTimeout(() => {
      this.element.remove();
    }, 5000)
  }

  close() {
    this.element.remove()
  }
}
