import ApplicationController from './application'

export default class extends ApplicationController {

  static targets = [
    'input',
    'openEye',
    'closedEye',
    'validation'
  ]

  toggle() {
    /** Shows and hides password on the form */
    if (!this.hasInputTarget && !this.hasIconTarget) return

    const input = this.inputTarget
    const newType = input.type === 'password' ? 'text' : 'password'
    input.setAttribute('type', newType)

    if (newType === 'password') {
      this.openEyeTarget.classList.remove('hidden')
      this.closedEyeTarget.classList.add('hidden')
    } else {
      this.openEyeTarget.classList.add('hidden')
      this.closedEyeTarget.classList.remove('hidden')
    }
  }

  validate() {
    /** validates the password */
    if (!this.hasValidationTarget) return

    const input = this.inputTarget
    const limitCheck = this.validationTarget.querySelector('.limit')
    const numberCheck = this.validationTarget.querySelector('.number')
    const caseCheck = this.validationTarget.querySelector('.case')
    const validationGroup = this.validationTarget

    input.setAttribute('pattern', '^(?=.*[A-Z])(?=.*[0-9]).{8,128}$')
    this.validateLine(input.value.match('(?=.*[A-Z])'), caseCheck)
    this.validateLine(input.value.match('(?=.*[0-9])'), numberCheck)
    this.validateLine((input.value.length >= 8 && input.value.length <= 128), limitCheck)
    this.validateLine(!(input.value.length > 0), validationGroup, 'hidden')
  }

  validateLine(test, element, className = 'valid') {
    test ? element.classList.add(className) : element.classList.remove(className)
  }
}
