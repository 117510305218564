import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    this.element.focus();
  }

  backdropHide(event) {
    const tag = event.target.tagName.toLowerCase()
    tag != 'a' && tag != 'button' && event.preventDefault();

    const element = event.target.getAttribute('id')
    element === 'modal' && this.element.remove();
  }

  hide() {
    this.element.remove();
  }

  hideOnSubmit(event) {
    if (event.detail.success) {
      this.hide();
    }
  }

  disconnect() {
    this.#modalTurboFrame.src = null;
  }

  // private

  get #modalTurboFrame() {
    return document.querySelector("turbo-frame[id='c_modal']");
  }
}
