import ApplicationController from './application'

export default class extends ApplicationController {

  static targets = [
    'input',
    'tooltip',
    'defaultIcon',
    'successIcon',
    'defaultMessage',
    'successMessage'
  ]

  mouseover() {
    if (!this.hasTooltipTarget) return

    const tooltip = this.tooltipTarget

    tooltip.classList.remove('invisible');
    tooltip.classList.remove('opacity-0');
  }

  mouseout() {
    if (!this.hasTooltipTarget) return

    const tooltip = this.tooltipTarget

    tooltip.classList.add('invisible');
    tooltip.classList.add('opacity-0');
  }

  copy() {
    if (!this.hasInputTarget && !this.hasTooltipTarget) return
    if (!this.hasDefaultIconTarget && !this.hasSuccessIconTarget) return
    if (!this.hasDefaultMessageTarget && !this.hasSuccessMessageTarget) return

    const input = this.inputTarget
    const tooltip = this.tooltipTarget
    const defaultIcon = this.defaultIconTarget
    const successIcon = this.successIconTarget
    const defaultMessage = this.defaultMessageTarget
    const successMessage = this.successMessageTarget
    // Copy to clipboard
    navigator.clipboard.writeText(input.value)

    this.showSuccess(tooltip, defaultIcon, successIcon, defaultMessage, successMessage);

    setTimeout(() => {
      this.resetToDefault(tooltip, defaultIcon, successIcon, defaultMessage, successMessage);
    }, 2000);
  }

  showSuccess(tooltip, defaultIcon, successIcon, defaultMessage, successMessage) {
    defaultIcon.classList.add('hidden');
    successIcon.classList.remove('hidden');
    defaultMessage.classList.add('hidden');
    successMessage.classList.remove('hidden');
    tooltip.classList.remove('opacity-0');
    tooltip.classList.remove('invisible');
  }

  resetToDefault(tooltip, defaultIcon, successIcon, defaultMessage, successMessage) {
    defaultIcon.classList.remove('hidden');
    successIcon.classList.add('hidden');
    defaultMessage.classList.remove('hidden');
    successMessage.classList.add('hidden');
    tooltip.classList.add('opacity-0');
    tooltip.classList.add('invisible');
  }
}
