import ApplicationController from './application'

export default class extends ApplicationController {

  static outlets = []

  static targets = [
    'input1',
    'input2',
    'input3',
    'input4',
    'input5',
    'input6',
    'submitButton'
  ]

  static values = {}

  static classes = []

  initialize() {
  }

  // ==== Actions

  // ==== Callbacks

  // ==== Private

  keyup(event, currentInputIndex, nextInputIndex) {
    if (event.target.value.length === 6) {
      /** If the OTP is auto-filled,
       * this will allocate the numbers in their right boxes */
      Array.from(event.target.value).forEach((item, index) => {
        if (this[`hasInput${index + 1}Target`]) {
          this[`input${index + 1}Target`].value = item
        }
        /** Submit when all boxes are populated */
        if (index === 5) this.submitButtonTarget.click()
      });
    } else if (event.target.value.length === 1 && this[`hasInput${nextInputIndex}Target`]) {
      this[`input${nextInputIndex}Target`].focus()
    } else if (event.target.value.length === 0 && this[`hasInput${currentInputIndex - 1}Target`]) {
      this[`input${currentInputIndex - 1}Target`].focus()
    }
  }

  keyup1(event) {
    /** Only allow one value when typing or six for autofill */
    const currentValueLength = event.target.value.length
    if (currentValueLength !== 0 && currentValueLength !== 1 && currentValueLength !== 6) {
      this[`input${1}Target`].value = event.target.value.charAt(0)
    }
    setTimeout(() => {
      this.keyup(event, 1, 2)
    }, 200);
  }

  keyup2(event) {
    this.keyup(event, 2, 3)
  }

  keyup3(event) {
    this.keyup(event, 3, 4)
  }

  keyup4(event) {
    this.keyup(event, 4, 5)
  }

  keyup5(event) {
    this.keyup(event, 5, 6)
  }

  keyup6(event) {
    if (event.target.value.length === 1 && this.hasSubmitButtonTarget) {
      this.submitButtonTarget.click()
    } else if (event.target.value.length === 0 && this.hasInput5Target) {
      this.input5Target.focus()
    }
  }

}
